import React, {Component} from "react";

import './Content.scss';

function Kontakty() {
    return (
        <div className="root ome-root" id="5">
            <div className="karticka karticka-kontakty">

                    <span>jan.siegl@pm.me</span>
                    <span>+420 720 181 778</span>

            </div>
        </div>
    )
}

export default Kontakty;
